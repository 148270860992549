import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BoxLayout, Text } from '../../components';
import Stepper from '../../components/Stepper';

function WelcomeVideo({ classes, phoneNumber, redirectToPlayStore, i18n }) {

  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <Stepper activeStep={2} isEvaluation />
        <Box className={classes.container}>
          <iframe
            title="video"
            width="320"
            height="320"
            src="https://www.youtube.com/embed/uMzqTo96Hho?autoplay=1"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </Box>
        <Text className={classes.header}>Tu préstamo fue pre aprobado</Text>
        <Text className={classes.body}>{`Te haremos una llamada en los próximos 5 minutos para continuar con tu proceso desde el número: ${phoneNumber}. Guarda nuestro número para que no te sorprenda`}</Text>
        <button onClick={() => redirectToPlayStore()} className={classes.button} type="button">
          <Text className={classes.buttonText}>
            Descargar {i18n.appName} app
          </Text>
        </button>
      </BoxLayout>
    </BoxLayout>
  );
}

WelcomeVideo.propTypes = {
  classes: PropTypes.objectOf.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  redirectToPlayStore: PropTypes.func.isRequired,
};

export default WelcomeVideo;
