import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment } from '@material-ui/core';
import { Done } from '@material-ui/icons';

import {
  BoxLayout,
  Button,
  CodeInput,
  MenuItem,
  Text,
  TextInput,
} from '../../components';
import Images from '../../assets/images';

export function Home({
  classes,
  _handleChangePhone,
  _onContinueAction,
  errorPhone,
  isValidate,
  hasRequestProcess,
  _handleChangeCode,
  errorCode,
  phoneNumber,
  isValidateCode,
  _redirectAction,
  handleChangeInputLada,
  lada,
  isStepToValidate,
  _onContinueValidateNip,
  resendNip,
  isDisabled,
  minutes,
  seconds,
  backHome,
  i18n,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        {!hasRequestProcess && !isStepToValidate && (
          <>
            <div className={classes.imagesContainer}>
              <img
                className={classes.backgroundImage}
                src={process.env.REACT_APP_MAP_COUNTRY === 'mx'
                  ? Images.approvedLoan
                  : Images.homeRD}
                alt="img-background"
              />
              {process.env.REACT_APP_MAP_COUNTRY === 'mx' && <img
                className={classes.yofioImage}
                src={Images.yofio}
                alt="img-yofio"
              />}
            </div>
            <Text
              className={classes.mainTitle}
              variant="h2"
              color="initial"
              align="justify"
            >
              Surte tu negocio con {i18n.appName}
            </Text>
            <Text
              className={classes.subTitle}
              variant="h4"
              color="initial"
              align="left"
            >
              Ingresa tu número celular, te enviaremos un código para iniciar la solicitud.
            </Text>
            <BoxLayout className={classes.bodyContainer}>
              <div className={classes.phoneContainer}>
                <TextInput
                  variant="standard"
                  select
                  value={lada}
                  onChange={handleChangeInputLada("lada")}
                  focused={lada !== "" ? true : false}
                >
                  {process.env.REACT_APP_MAP_COUNTRY === 'mx' && (
                    <MenuItem value="+52">+52</MenuItem>
                  )}
                  {process.env.REACT_APP_MAP_COUNTRY === 'do' && (
                    <MenuItem value="+1">+1</MenuItem>
                  )}
                </TextInput>
                <TextInput
                  className={classes.phoneInput}
                  error={errorPhone !== ""}
                  label="Teléfono celular"
                  type="tel"
                  value={phoneNumber}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 10,
                  }}
                  variant="standard"
                  onChange={_handleChangePhone}
                  helperText={errorPhone}
                  focused={isValidate ? true : false}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      {isValidate &&
                        <Done
                          style={{ color: "#009805" }}
                        />
                      }
                    </InputAdornment>
                  }}
                />
              </div>
            </BoxLayout>
            <Button
              className={classes.continueButton}
              variant="contained"
              color="primary"
              isDisabled={!isValidate}
              onClick={() => _onContinueAction()}
            >
              Continuar
            </Button>
            <Text
              className={classes.warningText}
              variant="h4"
              color="#2b2c49"
              align="center"
            >
              Al ingresar tu número de celular, aceptas nuestro&nbsp;
              <a
                onClick={() => _redirectAction()}
                className={classes.hiperTextPrivacy}
              >
                Aviso de Privacidad
              </a>
              .
            </Text>
          </>
        )}
        {hasRequestProcess ||
          (isStepToValidate && (
            <>
              <Text
                className={classes.mainTitleCode}
                variant="h2"
                color="initial"
                align="justify"
              >
                Código de seguridad.
              </Text>
              <Text
                className={classes.subTitleCode}
                variant="h4"
                color="initial"
                align="left"
              >
                Escribe el código de seguridad que  enviamos via SMS al:&nbsp;
                <a
                  href={`tel:${lada}${phoneNumber}`}
                  className={classes.hiperTextPrivacy}
                >
                  {phoneNumber}
                </a>
              </Text>
              <BoxLayout className={classes.bodyContainer}>
                <Text
                  className={classes.labelCode}
                  variant="h4"
                  color="initial"
                  align="justify"
                >
                  Código de seguridad
                </Text>
                <CodeInput
                  length={6}
                  onChange={_handleChangeCode}
                  type="numeric"
                  inputMode="number"
                  style={{ display: "flex", justifyContent: "center", marginTop: 5}}
                  inputStyle={{
                    borderColor: "#4a4b4b",
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    width: "25%",
                  }}
                  inputFocusStyle={{ borderColor: "#3050ff" }}
                  autoSelect
                />
                {errorCode !== "" && (
                  <Text
                    className={classes.labelErrorCode}
                    variant="h5"
                    color="initial"
                    align="justify"
                  >
                    {errorCode}
                  </Text>
                )}
                <div className={classes.containerResendNip}>
                  <Button
                    newCustomStyle={styles.resendNipButton}
                    variant="text"
                    color="primary"
                    onClick={() => resendNip()}
                    isDisabled={isDisabled}
                  >
                    Reenviar Código
                  </Button>
                  { isDisabled === true &&
                    <Text
                      className={classes.textTime}
                      variant="h4"
                      color="initial"
                      align="left"
                    >
                      {`${minutes} : ${seconds}`}
                    </Text>
                  }
                </div>
              </BoxLayout>
              <Button
                newCustomStyle={styles.buttonStyle}
                variant="text"
                color="primary"
                onClick={() => backHome()}
              >
                Cambiar número télefonico
              </Button>
              <Button
                className={classes.continueButton}
                variant="contained"
                color="primary"
                isDisabled={!isValidateCode}
                onClick={() => _onContinueValidateNip()}
              >
                Continuar
              </Button>
            </>
          ))}
      </BoxLayout>
    </BoxLayout>
  );
}

export default Home;

const styles = {
  resendNipButton: {
    backgroundColor: "#FFF",
    color: "#3050ff",
    fontSize: '13px',
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonStyle: {
    backgroundColor: "#ffffff",
    color: "#3050ff",
    marginTop: 70,
  },
};


Home.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  _handleChangePhone: PropTypes.func,
  _onContinueAction: PropTypes.func,
  errorPhone: PropTypes.string,
  isValidate: PropTypes.bool,
  hasRequestProcess: PropTypes.bool,
  _handleChangeCode: PropTypes.func,
  errorCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  isValidateCode: PropTypes.bool,
  _redirectAction: PropTypes.func,
  handleChangeInputLada: PropTypes.func,
  lada: PropTypes.string,
  isStepToValidate: PropTypes.bool,
  _onContinueValidateNip: PropTypes.func,
};

Home.defaultProps = {
  _handleChangePhone: () => {},
  _onContinueAction: () => {},
  errorPhone: "",
  isValidate: false,
  hasRequestProcess: false,
  _handleChangeCode: () => {},
  errorCode: "",
  phoneNumber: "",
  _onContinueCodeAction: () => {},
  isValidateCode: false,
  _redirectAction: () => {},
  handleChangeInputLada: () => {},
  lada: "",
  isStepToValidate: false,
  _onContinueValidateNip: () => {},
};
