import React from 'react';
import WelcomeVideoUI from './WelcomeVideo';
import useStyles from './WelcomeVideo.styles';
import I18n from '../../i18n';

function WelcomeVideo() {
  const classes = useStyles();
  const phoneNumber = process.env.REACT_APP_PHONE_TO_CALL.replace(/^tel:\+52/, '');
  const redirectToPlayStore = () => {
    window.location.href = process.env.REACT_APP_PLAYSTORE_LINK;
    window.analytics.track('Clic a descargar app YoFio desde Landing');
  };
  const i18n = I18n(process.env.REACT_APP_MAP_COUNTRY);
  return (
    <WelcomeVideoUI
      classes={classes}
      phoneNumber={phoneNumber}
      redirectToPlayStore={redirectToPlayStore}
      i18n={i18n}
    />
  );
}

export default WelcomeVideo;
